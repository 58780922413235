import { Splide } from '@splidejs/splide';
import Rellax from 'rellax';
import { WebpMachine } from 'webp-hero';

const featureCardsLeft = document.querySelectorAll(
  '.feature-cards-content-left .feature-card[data-card]'
);
const featureCardsRight = document.querySelectorAll(
  '.feature-cards-content-right .feature-card[data-card]'
);
const sectionLeft = document.querySelector('section.feature-cards-left');
const sectionRight = document.querySelector('section.feature-cards-right');
const desktop = window.matchMedia('(min-width: 992px)');

// Show feature card images on desktop
function showImage(event, context) {
  const cardNum = event.currentTarget.dataset.card;
  const targetImage = context.querySelector(
    `.feature-image[data-card="${cardNum}"]`
  );
  const otherImages = context.querySelectorAll(
    `.feature-image[data-card]:not([data-card="${cardNum}"])`
  );
  targetImage.style.zIndex = '10';
  otherImages.forEach((image) => {
    image.classList.remove('show');
  });
  targetImage.classList.add('show');
  targetImage.style.zIndex = '1';
}

const handleLeftDesktop = (e) => {
  if (desktop.matches) {
    showImage(e, sectionLeft);
  }
};
const handleRightDesktop = (e) => {
  if (desktop.matches) {
    showImage(e, sectionRight);
  }
};

// Show featured cards on tablet and below
function openCard(event, context) {
  const wrapper = event.currentTarget.closest('.feature-card-wrapper');
  const targetImage = wrapper.querySelector('.feature-image');
  const targetBody = wrapper.querySelector('.feature-card-body');
  const contextImages = context.querySelectorAll('.feature-image');
  const contextWrappers = context.querySelectorAll('.feature-card-wrapper');
  const contextCards = context.querySelectorAll('.feature-card');
  const contextBody = context.querySelectorAll('.feature-card-body');

  contextCards.forEach((element) => {
    element === event.currentTarget
      ? element.classList.add('focused')
      : element.classList.remove('focused');
  });

  contextBody.forEach((element) => {
    element === targetBody
      ? element.classList.add('focused')
      : element.classList.remove('focused');
  });

  contextWrappers.forEach((element) => {
    element === wrapper
      ? element.classList.add('show')
      : element.classList.remove('show');
  });
  contextImages.forEach((image) => {
    image === targetImage
      ? image.classList.add('show')
      : image.classList.remove('show');
  });

  event.currentTarget.scrollIntoView({ behavior: 'smooth' });
}

const handleLeftMobile = (e) => {
  if (!desktop.matches) {
    openCard(e, sectionLeft);
  }
};

const handleRightMobile = (e) => {
  if (!desktop.matches) {
    openCard(e, sectionRight);
  }
};

// Attach event listeners for desktop hover and mobile click
if (featureCardsLeft) {
  featureCardsLeft.forEach((card) => {
    card.addEventListener('mouseover', handleLeftDesktop);
    card.addEventListener('click', handleLeftMobile);
  });
}

if (featureCardsRight) {
  featureCardsRight.forEach((card) => {
    card.addEventListener('mouseover', handleRightDesktop);
    card.addEventListener('click', handleRightMobile);
  });
}

// Splide configuration
if (document.querySelector('.splide')) {
  const splide = new Splide('.splide', {
    type: 'loop',
    pagination: false,
    arrows: false,
    autoplay: true,
  });

  splide.mount();
}

// Update copyright date on load
const copyright = document.querySelector('#copyright');
copyright
  ? (copyright.innerHTML = `\u00A9 ${new Date().getFullYear()} Cadby All Rights Reserved`)
  : null;

// Rellax configuration
if (document.querySelector('.rellax')) {
  const rellax = new Rellax('.rellax');
}

// Convert WebP to PNG if unsupported
const webpMachine = new WebpMachine();
webpMachine.polyfillDocument();
